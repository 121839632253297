.footer {
  width: 100%;
  margin-top: auto;
  height: 150px;
  padding: 15px 0 0 0;
  color: #6c6c86;
  font-size: 11px;
  border-top: 1px solid #5c5c85;
  background: linear-gradient(to bottom, #353550 0%, #212239 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#353550', endColorstr='#212239',GradientType=0 ); }
  .footer #footer-middler {
    width: 1000px;
    height: 100%;
    margin: 0 auto; }
    @media all and (max-width: 1000px) {
      .footer #footer-middler p.copyright {
        margin-left: 20px; }
      .footer #footer-middler div.footer-social {
        margin-right: 20px; } }
    .footer #footer-middler p.copyright {
      float: left;
      color: #CCC; }
    .footer #footer-middler div.footer-social {
      float: right; }
      .footer #footer-middler div.footer-social p {
        display: flex;
        align-items: center;
        color: #CCC; }
        .footer #footer-middler div.footer-social p a {
          display: flex;
          align-items: center; }
          .footer #footer-middler div.footer-social p a img {
            width: 20px;
            height: 20px; }
          .footer #footer-middler div.footer-social p a:first-child img, .footer #footer-middler div.footer-social p a:last-child img {
            margin: 0 8px; }
