/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 3;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-align: left; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

#glow #footer #footer-middler .copyright {
  color: #CCC; }

#glow #footer #footer-middler .footer-social p {
  color: #CCCCCC; }

#footer #footer-middler .footer-social p {
  color: #CCCCCC; }

html, body, #root {
  min-width: 1000px;
  min-height: 100vh;
  margin: 0; }

body #root {
  font: 13px/16pt Helvetica, Arial, sans-serif;
  color: #555; }
  body #root .app-layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("/_ima/glow.jpg");
    background-position-x: center; }
    body #root .app-layout .content {
      flex-grow: 1;
      background-color: #fff;
      width: 1000px;
      min-height: 600px;
      margin: 3px 0 30px 0;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.4); }
      body #root .app-layout .content blockquote {
        margin-top: 0;
        margin-bottom: 0;
        padding: 13px 20px 13px 20px; }
      body #root .app-layout .content a {
        color: #416ca6;
        text-decoration: none;
        outline: none; }
      body #root .app-layout .content p {
        padding: 0 0 10px 0;
        color: #555; }
      body #root .app-layout .content h2 {
        color: #333;
        font-size: 28px;
        line-height: 28px; }
      body #root .app-layout .content h3 {
        color: #336699;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        padding: 0 0 5px 0; }
      body #root .app-layout .content .btn {
        display: inline-block;
        padding: 6px 18px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid #a7a9aa;
        border-radius: 4px;
        color: #ffffff !important;
        text-decoration: none; }
      body #root .app-layout .content .btn:hover {
        text-decoration: none; }
      body #root .app-layout .content .btn-blue {
        background-color: #406ba5;
        border-color: #406ba5; }
      body #root .app-layout .content .btn-blue:hover,
      body #root .app-layout .content .btn-blue:focus,
      body #root .app-layout .content .btn-blue:active,
      body #root .app-layout .content .btn-blue.active {
        background-color: #395f93;
        border-color: #325380; }
      body #root .app-layout .content .btn-main {
        padding: 8px 36px; }

.ReactModalPortal {
  font: 13px/16pt Arial, Helvetica, sans-serif;
  font: 13px/16pt Arial, Helvetica, sans-serif;
  font: 13px/16pt Arial, Helvetica, sans-serif; }
  .ReactModalPortal .our-history {
    background-image: url(/_ima/glow.jpg);
    background-position-x: center; }
    .ReactModalPortal .our-history .modal-header {
      display: flex;
      align-items: center; }
      .ReactModalPortal .our-history .modal-header h1 {
        position: relative;
        margin: 0 auto 0 40px;
        color: white;
        font-size: 38px;
        line-height: 38px; }
        .ReactModalPortal .our-history .modal-header h1 hr {
          position: absolute;
          left: -20px;
          width: 75px;
          margin: 10px 0 0 0;
          background: white;
          height: 2px; }
      .ReactModalPortal .our-history .modal-header a {
        margin-right: 20px; }
    .ReactModalPortal .our-history .modal-body {
      background-color: white;
      padding: 20px;
      display: flex; }
      .ReactModalPortal .our-history .modal-body .text-wrapper {
        padding-right: 20px; }
        .ReactModalPortal .our-history .modal-body .text-wrapper p {
          margin: 8px 0;
          text-align: justify;
          font-size: 13px; }
          .ReactModalPortal .our-history .modal-body .text-wrapper p strong {
            font-weight: 900; }
        .ReactModalPortal .our-history .modal-body .text-wrapper h5 {
          margin: 36px 0;
          font-weight: 900;
          font-size: 13px; }
      .ReactModalPortal .our-history .modal-body .image-wrapper {
        margin: 12px; }
        .ReactModalPortal .our-history .modal-body .image-wrapper img {
          width: 235px;
          max-height: 100%;
          -webkit-box-shadow: 0px 0px 40px 6px #3608f0;
          -moz-box-shadow: 0px 0px 40px 6px #3608f0;
          box-shadow: 0px 0px 40px 6px #3608f0; }
  .ReactModalPortal .appko_and_CIS {
    background-image: url(/_ima/glow.jpg);
    background-position-x: center; }
    .ReactModalPortal .appko_and_CIS .modal-header {
      display: flex;
      align-items: center; }
      .ReactModalPortal .appko_and_CIS .modal-header h1 {
        position: relative;
        margin: 0 auto 0 40px;
        color: white;
        font-size: 38px;
        line-height: 38px; }
        .ReactModalPortal .appko_and_CIS .modal-header h1 hr {
          position: absolute;
          left: -20px;
          width: 75px;
          margin: 10px 0 0 0;
          background: white;
          height: 2px; }
      .ReactModalPortal .appko_and_CIS .modal-header a {
        margin-right: 20px; }
    .ReactModalPortal .appko_and_CIS .modal-body {
      background-color: white;
      display: flex; }
      .ReactModalPortal .appko_and_CIS .modal-body .left-column {
        flex-direction: row; }
        .ReactModalPortal .appko_and_CIS .modal-body .left-column .content-wrapper p {
          padding: 12px;
          text-align: justify;
          font-size: 22px;
          line-height: 22px;
          margin: 0;
          display: block;
          width: 75%; }
        .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper {
          text-align: right;
          background-color: #242852;
          border: 2px solid #c1c8d6;
          border-left: 1px solid #c1c8d6;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          position: relative;
          margin-top: 24px; }
          .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper img {
            width: 430px;
            max-height: 100%; }
          .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper h4 {
            position: absolute;
            top: 18%;
            left: 50%;
            background-color: #dcdcdc;
            font-size: 24px;
            font-weight: bold; }
          .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper p {
            display: block;
            position: absolute;
            text-align: center;
            line-height: 1.3;
            top: 26%;
            left: 35%;
            background-color: #dcdcdc;
            font-size: 24px; }
            .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper p:first-letter {
              font-weight: bold; }
          .ReactModalPortal .appko_and_CIS .modal-body .left-column .image-wrapper span {
            font-weight: bold; }
      .ReactModalPortal .appko_and_CIS .modal-body .right-column {
        flex-direction: column; }
        .ReactModalPortal .appko_and_CIS .modal-body .right-column .content-header {
          display: flex; }
          .ReactModalPortal .appko_and_CIS .modal-body .right-column .content-header h5 {
            font-size: 20px;
            width: 50%;
            margin: 0;
            padding: 12px 8px;
            font-weight: bold; }
            .ReactModalPortal .appko_and_CIS .modal-body .right-column .content-header h5:first-child {
              background: linear-gradient(to bottom, #cbe3f5, #65a6db); }
            .ReactModalPortal .appko_and_CIS .modal-body .right-column .content-header h5:last-child {
              background: linear-gradient(to bottom, #d9d9d9, #949494); }
        .ReactModalPortal .appko_and_CIS .modal-body .right-column .content {
          display: flex; }
          .ReactModalPortal .appko_and_CIS .modal-body .right-column .content:nth-child(odd) p:first-child {
            background-color: #eaedff; }
          .ReactModalPortal .appko_and_CIS .modal-body .right-column .content:nth-child(odd) p:last-child {
            background-color: #efefef; }
          .ReactModalPortal .appko_and_CIS .modal-body .right-column .content p {
            display: block;
            width: 50%;
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            border: 1px solid #efefef;
            padding: 12px 8px; }
  .ReactModalPortal .appko-accolade {
    background-image: url(/_ima/glow.jpg);
    background-position-x: center; }
    .ReactModalPortal .appko-accolade .modal-header {
      display: flex;
      align-items: center; }
      .ReactModalPortal .appko-accolade .modal-header h1 {
        position: relative;
        margin: 0 auto 0 40px;
        color: white;
        font-size: 38px;
        line-height: 38px; }
        .ReactModalPortal .appko-accolade .modal-header h1 hr {
          position: absolute;
          left: -20px;
          width: 75px;
          margin: 10px 0 0 0;
          background: white;
          height: 2px; }
      .ReactModalPortal .appko-accolade .modal-header a {
        margin-right: 20px; }
    .ReactModalPortal .appko-accolade .modal-body {
      background-color: white;
      padding: 20px; }
      .ReactModalPortal .appko-accolade .modal-body .text-wrapper {
        display: flex; }
        .ReactModalPortal .appko-accolade .modal-body .text-wrapper .content {
          margin-right: 40px; }
          .ReactModalPortal .appko-accolade .modal-body .text-wrapper .content h3 {
            color: red;
            font-weight: bold;
            font-size: 22px;
            line-height: 24px; }
          .ReactModalPortal .appko-accolade .modal-body .text-wrapper .content p {
            display: block;
            font-size: 18px;
            line-height: 22px; }
            .ReactModalPortal .appko-accolade .modal-body .text-wrapper .content p:last-child {
              text-align: right;
              font-style: italic;
              font-size: 20px; }
        .ReactModalPortal .appko-accolade .modal-body .text-wrapper .image-wrapper {
          margin-left: 24px; }
          .ReactModalPortal .appko-accolade .modal-body .text-wrapper .image-wrapper img {
            width: 200px;
            max-height: 100%; }
      .ReactModalPortal .appko-accolade .modal-body .content-bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom h5 {
          color: red;
          font-weight: bold;
          font-size: 20px;
          margin: 12px 0 0 12px; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom p {
          display: block;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
          margin-left: 12px; }
          .ReactModalPortal .appko-accolade .modal-body .content-bottom p:last-child {
            font-style: italic;
            margin: 0;
            margin-left: 12px; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom div {
          border: 1px solid black; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom .first-block {
          width: 350px;
          height: 130px;
          margin-bottom: 16px; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom .second-block {
          width: 520px;
          height: 138px; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom .third-block {
          width: 500px;
          margin-top: auto; }
          .ReactModalPortal .appko-accolade .modal-body .content-bottom .third-block p:nth-child(3) {
            margin: 0;
            margin-left: 12px;
            font-style: italic; }
          .ReactModalPortal .appko-accolade .modal-body .content-bottom .third-block p:last-child {
            margin-bottom: 12px; }
        .ReactModalPortal .appko-accolade .modal-body .content-bottom .fourth-block {
          width: 400px;
          margin-top: auto; }
          .ReactModalPortal .appko-accolade .modal-body .content-bottom .fourth-block p:nth-child(3) {
            margin: 0;
            margin-left: 12px; }
          .ReactModalPortal .appko-accolade .modal-body .content-bottom .fourth-block p:last-child {
            margin-bottom: 12px; }
