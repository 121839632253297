#partners-customers {
  background: url("/_ima/partners-customers.jpg") repeat-x;
  padding: 20px 0 20px 0;
  width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  #partners-customers #partners-customers-left,
  #partners-customers #partners-customers-right {
    width: 48%; }
    #partners-customers #partners-customers-left h4,
    #partners-customers #partners-customers-right h4 {
      font-size: 15px;
      color: #000;
      font-weight: bold;
      padding: 0 0 10px 0; }
    #partners-customers #partners-customers-left .image-wrapper,
    #partners-customers #partners-customers-right .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center; }
      #partners-customers #partners-customers-left .image-wrapper img,
      #partners-customers #partners-customers-right .image-wrapper img {
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        width: 100%; }
