.submenu hr {
  width: 65%; }

.submenu .subnavigation {
  width: 1000px;
  padding: 50px 0 0 0;
  float: right; }
  .submenu .subnavigation li {
    float: right;
    padding: 0 0 0 25px;
    list-style-type: none; }
    @media all and (max-width: 1000px) {
      .submenu .subnavigation li:first-child {
        margin-right: 20px; } }
    .submenu .subnavigation li a {
      text-decoration: none;
      cursor: pointer;
      outline: none;
      color: #99ccff;
      font-size: 16px;
      transition: color 0.2s ease-in; }
      .submenu .subnavigation li a:active, .submenu .subnavigation li a:hover {
        color: #fff; }
      .submenu .subnavigation li a.navon {
        color: #fff;
        text-decoration: underline; }
