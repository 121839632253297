.header {
  width: 1000px;
  height: 111px;
  position: relative; }
  .header .navigation {
    width: 700px;
    padding: 50px 0 0 0;
    float: right; }
    .header .navigation li {
      float: right;
      padding: 0 0 0 25px;
      list-style-type: none; }
      @media all and (max-width: 1000px) {
        .header .navigation li:first-child {
          margin-right: 20px; } }
      .header .navigation li a {
        text-decoration: none;
        cursor: pointer;
        outline: none;
        color: #99ccff;
        font-size: 16px;
        transition: color 0.2s ease-in; }
        .header .navigation li a:active, .header .navigation li a:hover {
          color: #fff; }
        .header .navigation li a.navon {
          color: #fff;
          text-decoration: underline; }
